import {
  type CSSObject,
  type Theme
} from '@mui/material/styles';

export default function ButtonCommonStyles(variant: string | undefined, theme: Theme): CSSObject {
  const getButtonIconColorStyles = (color: keyof typeof theme.uiKit = 'buttonBrandedColor'): Record<string, unknown> => {
    return {
      '& > .MuiIcon-root, \
       & > .MuiSvgIcon-root, \
       & > [class^="icon-"], \
       & > .MuiButton-startIcon .MuiIcon-root, \
       & > .MuiButton-endIcon .MuiIcon-root': {
        color: theme.uiKit[color]
      }
    };
  };

  const defaultStyles = {
    backgroundColor: theme.uiKit.buttonDefaultBackground,
    border: theme.uiKit.buttonDefaultBorder,
    borderRadius: theme.uiKit.borderRadiusM,
    color: theme.uiKit.buttonColor,
    fontSize: theme.uiKit.buttonFont,
    fontWeight: theme.uiKit.buttonWeight,
    letterSpacing: 'normal',
    lineHeight: theme.uiKit.buttonLineHeight,
    minWidth: 'auto',
    margin: 0,
    '&:hover': {
      backgroundColor: theme.uiKit.buttonDefaultBackgroundHover,
      border: theme.uiKit.buttonDefaultBorderHover
    },
    '&.Mui-disabled': {
      color: theme.uiKit.buttonColor,
      '& > .MuiIcon-root, \
       & > .MuiSvgIcon-root, \
       & > [class^="icon-"]': {
        color: theme.uiKit.buttonColor
      }
    },
    '&.Mui-focusVisible': theme.mixins.componentFocusStyles(),
    '& > .MuiIcon-root, \
     & > .MuiSvgIcon-root, \
     & > [class^="icon-"], \
     & > .MuiButton-startIcon .MuiIcon-root, \
     & > .MuiButton-endIcon .MuiIcon-root': {
      opacity: 1,
      color: theme.uiKit.buttonColor,
      fontSize: theme.uiKit.buttonLineHeight
    },
    '&.MuiButton--off-screen': {
      ...theme.mixins.componentOffScreenStyles()
    },
    '&.Mui-disabled:disabled:not(.MuiButton--remove-disabled-styles), \
     &.Mui-disabled:disabled:hover:not(.MuiButton--remove-disabled-styles)': {
      ...getVariantDisabledStyles(variant, theme)
    }
  };

  if (variant === 'branded') {
    return {
      ...defaultStyles as object,
      backgroundColor: theme.uiKit.buttonBrandedBackground,
      borderColor: theme.uiKit.buttonBrandedBackground,
      color: theme.uiKit.buttonBrandedColor,
      ...getButtonIconColorStyles(),
      '&:hover': {
        backgroundColor: theme.uiKit.buttonBrandedBackgroundHover,
        borderColor: theme.uiKit.buttonBrandedBackgroundHover,
        color: theme.uiKit.buttonBrandedColorHover,
        ...getButtonIconColorStyles('buttonBrandedColorHover')
      }
    };
  }

  if (variant === 'branded-secondary') {
    return {
      ...defaultStyles as object,
      borderColor: theme.uiKit.buttonBrandedBackground,
      '&:hover': {
        backgroundColor: theme.uiKit.buttonBrandedBackgroundHover,
        borderColor: theme.uiKit.buttonBrandedBackgroundHover,
        color: theme.uiKit.buttonBrandedColorHover,
        ...getButtonIconColorStyles('buttonBrandedColorHover')
      }
    };
  }

  if (variant === 'destructive') {
    return {
      ...defaultStyles as object,
      backgroundColor: theme.uiKit.buttonDestructiveBackground,
      border: theme.uiKit.buttonDestructiveBorder,
      color: theme.uiKit.buttonDestructiveColor,
      ...getButtonIconColorStyles('buttonDestructiveColor'),
      '&:hover': {
        backgroundColor: theme.uiKit.buttonDestructiveBackgroundHover,
        border: theme.uiKit.buttonDestructiveBorder,
        color: theme.uiKit.buttonDestructiveColorHover,
        ...getButtonIconColorStyles('buttonDestructiveColorHover')
      }
    };
  }

  if (variant === 'ghost') {
    return {
      ...defaultStyles as object,
      backgroundColor: theme.uiKit.buttonGhostBackground,
      border: theme.uiKit.buttonGhostBorder,
      '&:hover': {
        backgroundColor: theme.uiKit.buttonGhostBackground,
        borderColor: theme.uiKit.buttonBrandedBackground
      }
    };
  }

  if (variant === 'ghost-link-branded') {
    return {
      ...defaultStyles as object,
      backgroundColor: theme.uiKit.buttonGhostBackground,
      border: theme.uiKit.buttonGhostBorder,
      color: theme.uiKit.linkColor,
      '&:hover': {
        backgroundColor: theme.uiKit.buttonGhostBackground,
        borderColor: theme.uiKit.linkColor
      }
    };
  }

  return defaultStyles;
}

export function getVariantDisabledStyles(variant: string | undefined, theme: Theme): object {
  const defaultStyles = {
    backgroundColor: 'transparent',
    border: theme.uiKit.buttonDefaultBorderDisabled,
    color: theme.uiKit.buttonDefaultColorDisabled,
    cursor: 'not-allowed',
    pointerEvents: 'auto',
    fontWeight: theme.uiKit.fontWeightBold,
    '& > .MuiIcon-root, \
     & > .MuiSvgIcon-root, \
     & > [class^="icon-"]': {
      color: theme.uiKit.buttonDefaultColorDisabled
    }
  };

  if (variant === 'branded') {
    return {
      ...defaultStyles as object,
      backgroundColor: theme.uiKit.buttonBrandedBackgroundDisabled,
      borderColor: theme.uiKit.buttonBrandedBorderColorDisabled,
      color: theme.uiKit.buttonBrandedColorDisabled
    };
  }

  if (variant === 'ghost' || variant === 'ghost-link-branded') {
    return {
      ...defaultStyles as object,
      border: theme.uiKit.buttonGhostBorderDisabled
    };
  }

  return defaultStyles;
}
